import { CUSTOM_VALIDATIONS } from "../../../utils/validations";
import { EBUSINESS_TYPE } from "../../../api/business-registration/types";
import BusinessTypeIcon from "assets/images/svgs/BusinessTypeIcon";
import { InputList, InputListType } from "components/common/types";
import { IStateAndCities } from "api";
import { convertToOptions } from "utils";
import { StyledOptionalBusinessDocuments } from "./styles";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import { VNinRegistrationStyles } from "./styles";
import InfoIcon from "assets/images/svgs/InfoIcon";

export enum BUSINESS_PLANS {
  BASIC = "BPLAN001",
  PREMIUM = "BPLAN003",
  ESSENTIAL = "BPLAN002",
  ENTERPRISE = "BPLAN004",
}

export const BUSINESS_TYPES = [
  {
    id: EBUSINESS_TYPE.SMALL,
    title: "Small Business",
    description: "My staff size is 2-9",
    icon: BusinessTypeIcon,
  },
  {
    id: EBUSINESS_TYPE.LARGE,
    title: "Large Organisation",
    description: "My staff size is 10 and above",
    icon: BusinessTypeIcon,
  },
];

export const BUSINESS_INFORMATION_INPUT_LISTS = ({
  businessSector,
  statesAndCities,
  stateId,
  userPlan,
}: {
  businessSector: InputListType["options"];
  statesAndCities: IStateAndCities[];
  stateId: string;
  userPlan: string | null;
}): InputList => {
  const states = convertToOptions({
    records: statesAndCities,
    label: "stateName",
    value: "_id",
    useIndex: true,
  });

  const selectedCities = statesAndCities[Number(stateId)]?.cities || [];

  const cities = convertToOptions({
    records: selectedCities,
    label: "cityName",
    value: "_id",
    useIndex: true,
  });

  return [
    {
      name: "businessName",
      label: "Business Name",
      required: true,
      validation: {
        required: "Enter business name",
        ...CUSTOM_VALIDATIONS.minLength(3),
      },
    },
    {
      name: "businessEmail",
      label: "Company Email",
      type: "email",
      required: true,
      validation: {
        required: "Enter your email",
        ...CUSTOM_VALIDATIONS.email,
      },
    },
    {
      name: "businessAddress",
      label: "Business Address",
      required: true,
      validation: { required: "Enter your business address" },
    },
    {
      name: "businessPhoneNumber",
      label: "Primary Phone Number",
      type: "tel",
      required: true,
      validation: {
        required: "Enter your phone number",
        ...CUSTOM_VALIDATIONS.phoneNumber,
      },
    },
    {
      name: "stateId",
      label: "State",
      inputType: "select",
      options: states,
      required: true,
      validation: { required: "Pick a state" },
    },
    {
      name: "cityId",
      label: "City",
      inputType: "select",
      options: cities,
      required: true,
      validation: { required: "Pick a city" },
    },
    {
      name: "businessSectorId",
      label: "Your Business Sector",
      inputType: "select",
      options: businessSector,
      required: true,
      validation: { required: "Pick business sector" },
    },
    {
      name: "businessEmblem",
      label: "Business Logo",
      inputType: "upload",
    },
    ...(userPlan && userPlan !== BUSINESS_PLANS.BASIC
      ? [
          {
            name: "business-documents",
            inputType: "element",
            children: (
              <StyledOptionalBusinessDocuments>
                Your business documents <span>(optional)</span>
              </StyledOptionalBusinessDocuments>
            ),
          },
          {
            name: "CACNumber",
            label: "CAC Registration Number",
          },
          {
            name: "utilityBill",
            label: "Utility Bill",
            inputType: "upload",
          },
          {
            name: "NIN",
            label: "VNIN",
          },
          {
            name: "instructions",
            inputType: "element",
            children: (
              <>
                <br />
                <VNinRegistrationStyles>
                  <InfoIcon style={{ fill: "#F5A346", width: "60px" }} />
                  <div>
                    <h4>Option 1</h4>
                    <p>To generate your VNIN by USSD</p>
                    <p>
                      Please dial <b> *346*3*Your NIN*715461#</b> <br /> By
                      “your NIN” we mean the NIN given to you by the Federal
                      Government.
                    </p>
                    <h4>Option 2</h4>
                    <p>
                      Download the NIMC app, login and then use 715461 as the
                      agent code to generate a VNIN token.
                    </p>
                  </div>
                </VNinRegistrationStyles>
              </>
            ),
          },
        ]
      : ([] as any)),
  ] as const;
};

export const BUSINESS_PLANS_PRICE_INDEX = [
  BUSINESS_PLANS.BASIC,
  BUSINESS_PLANS.ESSENTIAL,
  BUSINESS_PLANS.PREMIUM,
  BUSINESS_PLANS.ENTERPRISE,
];

export const ENTER_PASSCODE_INPUT_LIST = (
  getValues: UseFormGetValues<FieldValues>
): InputList => {
  return [
    {
      name: "pin",
      inputType: "otp",
      label: "Enter your passcode",
      required: true,
      numInputs: 4,
      shouldAutoFocus: false,
      validation: {
        required: "Enter passcode",
        ...CUSTOM_VALIDATIONS.minLengthCustom(4, "Pin must be 4 numbers"),
      },
      className: "col__spanfull",
    },
    {
      name: "confirmPin",
      inputType: "otp",
      label: "Confirm your passcode",
      required: true,
      numInputs: 4,
      shouldAutoFocus: false,
      validation: {
        required: "Re-Enter a passscode",
        ...CUSTOM_VALIDATIONS.minLengthCustom(4, "Pin must be 4 numbers"),
        validate: () =>
          getValues("pin") === getValues("confirmPin") || "Pin does not match",
      },
      className: "col__spanfull",
    },
  ];
};
export const CHANGE_PASSCODE_INPUT_LIST = (
  getValues: UseFormGetValues<FieldValues>,
  isReset = false
): InputList => {
  return [
    !isReset && {
      name: "oldPasscode",
      inputType: "otp",
      label: "Enter your old passcode",
      required: true,
      numInputs: 4,
      shouldAutoFocus: false,
      validation: {
        required: "Enter old passcode",
        ...CUSTOM_VALIDATIONS.minLengthCustom(4, "Pin must be 4 numbers"),
      },
      className: "col__spanfull",
    },
    {
      name: "newPasscode",
      inputType: "otp",
      label: "Enter your new passcode",
      required: true,
      numInputs: 4,
      shouldAutoFocus: false,
      validation: {
        required: "Enter new passcode",
        ...CUSTOM_VALIDATIONS.minLengthCustom(4, "Pin must be 4 numbers"),
      },
      className: "col__spanfull",
    },
    {
      name: "confirmNewPasscode",
      inputType: "otp",
      label: "Confirm your new passcode",
      required: true,
      numInputs: 4,
      shouldAutoFocus: false,
      validation: {
        required: "Re-Enter your new passscode",
        ...CUSTOM_VALIDATIONS.minLengthCustom(4, "Pin must be 4 numbers"),
        validate: () =>
          getValues("newPasscode") === getValues("confirmNewPasscode") ||
          "Pin does not match",
      },
      className: "col__spanfull",
    },
  ].filter(Boolean) as InputList;
};

export const LOGIN_PASS_CODE_INPUT_LIST: InputList = [
  {
    name: "pin",
    inputType: "otp",
    required: true,
    numInputs: 4,
    shouldAutoFocus: false,
    validation: {
      required: "Enter passcode",
      ...CUSTOM_VALIDATIONS.minLengthCustom(4, "Pin must be 4 numbers"),
    },
    className: "col__spanfull",
  },
];
